import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Table, Thead, Tbody, Tr, Th, Td, Box, Text, Divider, useTheme } from "@chakra-ui/react";

const ViewInvoice = ({ isOpen, onClose, invoice }) => {
  console.log({ invoice });
  const theme = useTheme();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invoice Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">Supplier</Td>
                  <Td>{invoice.supplier_name}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Invoice Number</Td>
                  <Td>{invoice.invoice_number}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">PO Number</Td>
                  <Td>{invoice.po_number}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Store Code</Td>
                  <Td>{invoice.store_code}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Store Name</Td>
                  <Td>{invoice.store_name?invoice.store_name:invoice.store_description}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Region</Td>
                  <Td>{invoice.store_classification?invoice.store_classification:invoice.store_region}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Distributor</Td>
                  <Td>{invoice.dist_name?invoice.dist_name:invoice.distributor_name}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Cases</Td>
                  <Td>{invoice.quantity}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Delivery Date</Td>
                  <Td>{invoice.delivery_date}</Td>
                </Tr>

                <Tr>
                  <Td fontWeight="bold">Comment</Td>
                  <Td>{invoice.invoice_comment?invoice.invoice_comment:invoice.order_comment}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Divider />
          <Box mb={4}>
            <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Article Code</Th>
                  <Th fontWeight="bold">Article Name</Th>
                  <Th fontWeight="bold">Pack Size</Th>
                  <Th fontWeight="bold">Quantity</Th>
                  <Th fontWeight="bold">Cost(excl)</Th>
                  <Th fontWeight="bold">VAT</Th>
                  <Th fontWeight="bold">Cost(incl)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(invoice.transaction_items || invoice.order_items).map((article, index) => (
                  <Tr key={index}>
                    <Td>{article.articlecode?article.articlecode:article.supplier_articlecode}</Td>
                    <Td>{article.article_desc?article.article_desc:article.article_name}</Td>
                    <Td>{article.article_packsize}</Td>
                    <Td>{article.quantity}</Td>
                    <Td>R{parseFloat(article.cost_excl).toFixed(2)}</Td>
                    <Td>R{parseFloat(article.cost_vat).toFixed(2)}</Td>
                    <Td>R{parseFloat(article.cost_incl).toFixed(2)}</Td>
                  </Tr>
                ))}
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td fontWeight="bold">{invoice.totalQuantity?invoice.totalQuantity:invoice.quantity}</Td>
                  <Td fontWeight="bold">R{parseFloat(invoice.cost_excl).toFixed(2)}</Td>
                  <Td fontWeight="bold">R{parseFloat(invoice.cost_vat).toFixed(2)}</Td>
                  <Td fontWeight="bold">R{parseFloat(invoice.cost_incl).toFixed(2)}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewInvoice;
