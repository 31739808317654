import React, { useState, useEffect } from "react";
import { Box, Button, Input, Select, Table, Thead, Tbody, Tr, Th, Td, Divider, useTheme,useToast } from "@chakra-ui/react";
import { FaPlus, FaTrash } from "react-icons/fa";
import axios from "axios";

const CaptureStep2 = ({ step1Data, step2Data, onSubmit , supplier, retailer, organisation}) => {
  const theme = useTheme();
  const toast=useToast();

  const [articles, setArticles] = useState([...step2Data]);
  const [allArticles, setAllArticles] = useState([]);
  const [newArticle, setNewArticle] = useState({
    supplier_articlecode: "",
    quantity: "",
    article_cost: "",
    article_desc: "",
    article_packsize: "",
  });

  const [errors, setErrors] = useState([]);

  const addArticle = () => {
    setArticles([...articles, { ...newArticle, isSaved: false }]);
    setNewArticle({
      articlecode: "",
      article_desc: "",
      cost_excl: "",
      cost_incl: "",
      article_packsize: "",
      quantity: "",
      cost_vat: "",
    });
  };

  useEffect(() => {
    const fetchStores = async () => {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-dd-articles`, { supplier, retailer, organisation });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
        return;
      }
      setAllArticles(response.data.data.articles);
    };

    fetchStores();
  }, [supplier]);

  useEffect(() => {
    onSubmit(articles);
  }, [articles, onSubmit]);

  const handleArticleCode = (index, value) => {
    const selectedArticle = allArticles.find((article) => article.supplier_articlecode === value);
    if (selectedArticle) {

      const updatedArticles = articles.map((article, i) => {
        if (i === index) {
          const article_cost_excl = parseFloat(parseFloat(selectedArticle.article_cost)*parseFloat(article.quantity)).toFixed(2);
          const article_vat = parseFloat(article_cost_excl * 0.15).toFixed(2);
          const article_cost_incl = parseFloat(article_cost_excl * 1.15).toFixed(2);
          return {
            ...article,
            supplier_articlecode: selectedArticle.supplier_articlecode,
            article_desc: selectedArticle.article_desc,
            article_packsize: selectedArticle.article_packsize,
            article_cost: selectedArticle.article_cost,
            cost_excl: article_cost_excl,
            cost_vat: article_vat,
            cost_incl: article_cost_incl,
          };
        }
        return article;
      });

      setArticles(updatedArticles);
    }

  };
  const handleArticleDesc = (index, value) => {
    const selectedArticle = allArticles.find((article) => article.article_desc === value);
    if (selectedArticle) {
      const updatedArticles = articles.map((article, i) => {
        if (i === index) {
          const article_cost_excl = parseFloat(parseFloat(selectedArticle.article_cost)*parseFloat(article.quantity)).toFixed(2);
          const article_vat = parseFloat(article_cost_excl * 0.15).toFixed(2);
          const article_cost_incl = parseFloat(article_cost_excl * 1.15).toFixed(2);
          return {
            ...article,
            supplier_articlecode: selectedArticle.supplier_articlecode,
            article_desc: selectedArticle.article_desc,
            article_packsize: selectedArticle.article_packsize,
            article_cost: selectedArticle.article_cost,
            cost_excl: article_cost_excl,
            cost_vat: article_vat,
            cost_incl: article_cost_incl,
          };
        }
        return article;
      });
      setArticles(updatedArticles);
    }

  };
  const handlePackSize = (index, value) => {
    let field = "article_packsize";
    let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
    setArticles(updatedArticles);
  };
  const handleQuantity = (index, value) => {
    let field = "quantity";
    if (value == "") {
      let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
      setArticles(updatedArticles);
    } else {
      let updatedArticles = articles.map((article, i) => {
        if (i === index) {
          /*
                      article_packsize: selectedArticle.article_packsize,
            article_cost: selectedArticle.article_cost,
            cost_excl: article_cost_excl,
            cost_vat: article_vat,
            cost_incl: article_cost_incl,
          */
          const cost_excl= parseFloat(article.article_cost * value);
          const cost_vat=cost_excl*.15;
          const cost_incl=cost_excl*1.15;
          return {
            ...article,
            [field]: value,
            cost_excl: cost_excl.toFixed(2),
            cost_vat: cost_vat.toFixed(2),
            cost_incl: cost_incl.toFixed(2),
          };
        }
        return article;
      });
      setArticles(updatedArticles);
    }
  };
  const handleCostExcl = (index, value) => {
    let field = "cost_excl";
    let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
    setArticles(updatedArticles);
  };
  const handleCostIncl = (index, value) => {
    let field = "cost_incl";
    let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
    setArticles(updatedArticles);
  };
  const handleCostVat = (index, value) => {
    let field = "cost_vat";
    let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
    setArticles(updatedArticles);
  };
  const handleSoh = (index, value) => {
    let field = "article_soh";
    let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
    setArticles(updatedArticles);
  };

  CaptureStep2.verify = async () => {
    // const newErrors = articles.map((article, index) => ({
    //   articlecode: !article.articlecode,
    //   quantity: !article.quantity,
    //   cost_excl: !article.cost_excl,
    //   article_desc: !article.article_desc,
    //   article_packsize: (!article.article_packsize&&article.article_packsize!=0),
    //   cost_vat: !article.cost_vat,
    //   cost_incl: !article.cost_incl,
    // }));

    // setErrors(newErrors);

    // if (newErrors.some((error) => Object.values(error).some((e) => e))) {
    //   alert("Please fill in all fields for each article.");
    //   return false;
    // }
    return true;
  };

  const handleRemove = (index) => {
    setArticles((currentArticles) => {
      return currentArticles.filter((_, i) => i !== index);
    });
  };

  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>PO Number</Th>
              <Th>Order Date</Th>
              <Th>Delivery Date</Th>
              <Th>Store Code</Th>
              <Th>Store Name</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr key={1}>
              <Td>{step1Data.po_number}</Td>
              <Td>{step1Data.order_date}</Td>
              <Td>{step1Data.delivery_date}</Td>
              <Td>{step1Data.store_code}</Td>
              <Td>{step1Data.store_description}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Divider />
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>Article Number</Th>
              <Th>Article Description</Th>
              <Th>Pack Size</Th>
              <Th>Soh</Th>
              <Th>Quantity</Th>
              <Th>Cost Price(excl)</Th>
              <Th>VAT</Th>
              <Th>Cost Price(incl)</Th>
              <Th>
                <Button rightIcon={<FaPlus />} onClick={addArticle} ml={2} size="xs"
                 colorScheme="customRed"
                 variant="outline">
                  Add
                </Button>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {articles.map((article, index) => (
              <Tr key={index}>
                <Td>
                  <Select value={article.supplier_articlecode} onChange={(e) => handleArticleCode(index, e.target.value)} placeholder="Select Article">
                  {allArticles.map((article) => (
                      <option key={article.id} value={article.supplier_articlecode}>
                        {`${article.supplier_articlecode}`}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                <Select value={article.article_desc} onChange={(e) => handleArticleDesc(index, e.target.value)} placeholder="Select Article Description" borderColor={errors[index]?.article_desc ? "crimson" : theme.colors.primary2} errorBorderColor="crimson">
                    {allArticles.map((article) => (
                      <option key={article.id} value={article.article_desc}>
                        {article.article_desc}
                      </option>
                    ))}
                  </Select>
                </Td>

                <Td>
                  <Input value={article.article_packsize} onChange={(e) => handlePackSize(index, e.target.value)} />
                </Td>
                <Td>
                  <Input value={article.article_soh} onChange={(e) => handleSoh(index, e.target.value)} />
                </Td>
                <Td>
                  <Input type="number" value={article.quantity} 
                  onChange={(e) => handleQuantity(index, e.target.value)} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.cost_excl} 
                  onChange={(e) => handleCostExcl(index, e.target.value)} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.cost_vat} 
                  onChange={(e) => handleCostVat(index, e.target.value)} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.cost_incl}
                   onChange={(e) => handleCostIncl(index, e.target.value)} />
                </Td>
                <Td>
                  <Button
                    rightIcon={<FaTrash />}
                    onClick={() => handleRemove(index)} // This should be a callback that returns a function
                    ml={2} size="xs"
                    colorScheme="customRed"
                    variant="outline">
                    Remove
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default CaptureStep2;
