import React,{useState,useEffect} from "react";
import { Box, Flex, Stat, StatLabel, StatNumber, StatHelpText,Divider, useColorModeValue } from "@chakra-ui/react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";


const InvoiceStats = ({TOTALS,stats }) => {
  const bgColor = useColorModeValue("#e6e6e6", "#dedede");
 
  const [isBalanced, setIsBalanced] = useState(true);

  useEffect(() => {
    Object.keys(stats).forEach(function(articlecode){
      let item=stats[articlecode];
      let quantity=item.quantity;
      let invoiced_cases=item.total_cases;
      if(quantity!==invoiced_cases){
        setIsBalanced(false);
      }
    })
  }, [stats]);

  return (
    <Box p={4} bg={bgColor} borderRadius="md" boxShadow="md" minWidth="600px">
      <Flex justify="space-around" align="center">
        <Stat>
          <StatLabel>Cases Invoiced</StatLabel>
          <StatNumber>{TOTALS.TOTAL_quantity}</StatNumber>
        </Stat>
        <Divider orientation="vertical" mx={4} height="60px" borderColor="black"/>
        <Stat>
          <StatLabel>Cases Delivered</StatLabel>
          <StatNumber>{TOTALS.TOTAL_delivered}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Cases Not Delivered</StatLabel>
          <StatNumber>{TOTALS.TOTAL_returned}</StatNumber>
        </Stat>
        <Stat>
          {isBalanced ? (
            <>
              <Flex align="center">
                <CheckCircleIcon w={6} h={6} color="green.500" mr={2} />
                <StatHelpText color="green.500">Invoice Balances</StatHelpText>
              </Flex>
            </>
          ) : (
            <>
              <Flex align="center">
                <CloseIcon w={5} h={5} color="red.500" mr={2} />
                <StatHelpText color="red.500">Invoice Does Not Balance</StatHelpText>
              </Flex>
            </>
          )}
        </Stat>
      </Flex>
    </Box>
  );
};

export default InvoiceStats;
