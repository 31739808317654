import React, { useState, useEffect } from "react";
import { Box, Flex, Button, Table, Thead, Tbody, Tr, Th, Td, useTheme, useToast } from "@chakra-ui/react";
import StockStats from "../StockStats";
import axios from "axios";

const CaptureStep2 = ({ articleData, stockItem, totalsData, onSubmit }) => {
  const theme = useTheme();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const [balance, setBalance] = useState(false);

  const handleSubmit = async () => {
    onSubmit(articleData,totalsData);

  };

  useEffect(() => {
    if (parseInt(stockItem.totalCases) == parseInt(totalsData.article_quantity)) {
      setBalance(true);
    } else {
      toast({
        title: `Quantity does not balance`,
        description: `Please press back and enter correct quantity's`,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [articleData, totalsData]);

  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <Table variant="striped" size="sm" maxWidth="400px" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Supplier Ref Nr</Th>
                <Th>Supplier</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr key={1}>
                <Td>{stockItem.id}</Td>
                <Td>{stockItem.supplierRef}</Td>
                <Td>{stockItem.supplier}</Td>
              </Tr>
            </Tbody>
          </Table>
          <StockStats totalCases={stockItem.totalCases} totalQuantity={totalsData.article_quantity} />
        </Flex>
      </Box>

      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>Article Number</Th>
              <Th>Article Name</Th>
              <Th>PackSize</Th>
              <Th>Quantity</Th>
              <Th>Cost (excl)</Th>
              <Th>vat</Th>
              <Th>Cost (incl)</Th>
              <Th>BB Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {articleData.map((article, index) => (
              <Tr key={index}>
                <Td> {article.supplier_articlecode} </Td>
                <Td> {article.article_desc} </Td>
                <Td> {article.article_packsize} </Td>
                <Td>{article.article_quantity}</Td>
                <Td>{article.article_cost_excl}</Td>
                <Td>{article.article_vat}</Td>
                <Td>{article.article_cost_incl}</Td>
                <Td>{article.article_bb_date}</Td>
              </Tr>
            ))}
            <Tr borderTop={"2px solid black"}>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td style={{ fontWeight: "bold" }}>{totalsData.article_quantity ? totalsData.article_quantity : 0}</Td>
              <Td style={{ fontWeight: "bold" }}>{totalsData.article_cost_excl > 0 ? totalsData.article_cost_excl : 0}</Td>
              <Td style={{ fontWeight: "bold" }}>{totalsData.article_vat > 0 ? totalsData.article_vat : 0}</Td>
              <Td style={{ fontWeight: "bold" }}>{totalsData.article_cost_incl > 0 ? totalsData.article_cost_incl : 0}</Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box mb={4}>
        <Button onClick={handleSubmit} isDisabled={!balance} isLoading={loading} loadingText="Submitting..." colorScheme="customRed" variant="outline">
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default CaptureStep2;
