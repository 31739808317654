import React, { useState, useEffect } from "react";
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, useTheme, useToast } from "@chakra-ui/react";
import { FaPrint } from "react-icons/fa";
import axios from "axios";

const CaptureStep3 = ({ step1Data, step2Data, onSubmit, warehouse, supplier, retailer, organisation, distributor }) => {
  const theme = useTheme();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [totals, setTotals] = useState({});

  const handleSubmit = async () => {
    setLoading(true);
    try {
      //const orderNumber = Math.floor(100000 + Math.random() * 900000);
      const createdDate = new Date().toLocaleDateString();
      const createdBy = localStorage.getItem("userid");

      let transaction = {
        //orderNumber,
        po_number: step1Data.po_number,
        order_date: step1Data.order_date,
        delivery_date: step1Data.delivery_date,
        store_code: step1Data.store_code,
        store_name: step1Data.store_name,

        cost_incl: totals.totalIncl,
        cost_excl: totals.totalExcl,
        cost_vat: totals.totalVat,
        quantity: totals.totalQuantity,

        created_date: createdDate,
        created_by: createdBy,
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders/submit-order`, 
        { warehouse, supplier, retailer, organisation, distributor, transaction, transaction_items: step2Data });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      const invoice_number = response.data.data.invoice_number || "";
      transaction.invoice_number = invoice_number;
      transaction.articles = step2Data;
      setOrderDetails(invoice_number);
      onSubmit(transaction);

      setLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    calculateTotalRow();
  }, [step2Data]);

  const calculateTotalRow = () => {
    let totalIncl = 0;
    let totalExcl = 0;
    let totalVat = 0;
    let totalQuantity = 0;
    for (let i = 0; i < step2Data.length; i++) {
      totalIncl += parseFloat(step2Data[i].article_cost_incl);
      totalExcl += parseFloat(step2Data[i].article_cost_excl);
      totalVat += parseFloat(step2Data[i].article_vat);
      totalQuantity += parseInt(step2Data[i].article_quantity);
    }
    totalIncl = parseFloat(totalIncl).toFixed(3);
    totalExcl = parseFloat(totalExcl).toFixed(3);
    totalVat = parseFloat(totalVat).toFixed(3);
    setTotals({ totalIncl, totalExcl, totalVat, totalQuantity });
  };

  const verify = async () => {
    // Perform validation or necessary checks
    // Return true if everything is valid, otherwise false
    return true;
  };

  CaptureStep3.verify = verify;
  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>PO Number</Th>
              <Th>Order Date</Th>
              <Th>Delivery Date</Th>
              <Th>Store Code</Th>
              <Th>Store Name</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr key={1}>
              <Td>{step1Data.po_number}</Td>
              <Td>{step1Data.order_date}</Td>
              <Td>{step1Data.delivery_date}</Td>
              <Td>{step1Data.store_code}</Td>
              <Td>{step1Data.store_name}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>Supplier Article Number</Th>
              <Th>Article Name</Th>
              <Th>Pack Size</Th>
              <Th>Quantity</Th>
              <Th>Article Cost</Th>
              <Th>Cost Price(excl)</Th>
              <Th>article_vat</Th>
              <Th>Cost Price(incl)</Th>
              <Th>SOH</Th>
            </Tr>
          </Thead>
          <Tbody>
            {step2Data.map((article, index) => (
              <Tr key={index}>
                <Td>{article.supplier_articlecode}</Td>
                <Td>{article.article_desc}</Td>
                <Td>{article.article_packsize}</Td>
                <Td>{article.article_quantity}</Td>
                <Td>{article.article_cost}</Td>
                <Td>{article.article_cost_excl}</Td>
                <Td>{article.article_vat}</Td>
                <Td>{article.article_cost_incl}</Td>
                <Td>{article.article_soh}</Td>
              </Tr>
            ))}
            <Tr>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>{totals.totalQuantity}</Td>
              <Td></Td>
              <Td>{totals.totalExcl}</Td>
              <Td>{totals.totalVat}</Td>
              <Td>{totals.totalIncl}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box mb={4}>
        {!orderDetails && (
          <Button onClick={handleSubmit} isLoading={loading} loadingText="Submitting..." colorScheme="customRed" variant="outline">
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CaptureStep3;
