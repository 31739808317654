import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, IconButton, Spinner, Flex, Box, Button, useTheme, useToast } from "@chakra-ui/react";
import { FaEye, FaPrint } from "react-icons/fa";
import axios from "axios";
import ViewInvoice from "../ViewInvoice";
import FilteringCancelled from "../filtering/FilteringCancelled";

const InvoicedTable = ({ organisation, warehouse, supplier, retailer }) => {
  const theme = useTheme();
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [page, setPage] = useState(0); // Add state for current page
  const [limit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const [filters, setFilters] = useState([]);
  const [invoice_numbers, setInvoice_numbers] = useState([]);
  const [po_numbers, setPo_numbers] = useState([]);
  const [store_codes, setStore_codes] = useState([]);
  const [store_descriptions, setStore_descriptions] = useState([]);
  const [order_dates, setOrder_dates] = useState([]);
  const [delivery_dates, setDelivery_dates] = useState([]);
  const [created_dates, setCreated_dates] = useState([]);
  const [user_names, setUser_names] = useState([]);
  

  const [downloadingInvoices, setDownloadingInvoices] = useState({});

  const handleViewInvoice = async (invoice, index) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transaction-items`, { invoice_number: invoice.invoice_number });

      invoice.transaction_items = response.data.data.transaction_items;

      setSelectedInvoice(invoice);
      setIsPopupOpen(true);
    } catch (error) {
      toast({
        title: "Failed to load order items",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleCloseInvoice = () => {
    setIsPopupOpen(false);
    setSelectedInvoice(null);
  };
  const fetchInvoices = async () => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transactions`,
       { organisation, retailer, supplier, filters, limit, offset: page * limit, entry: "invoices.cancelled",cancelled:true });
    if (response.data.status != "success") {
      toast({
        title: response.data.title,
        description: response.data.message,
        status: response.data.status,
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    setInvoices(response.data.data.transactions);
    setTotalCount(response.data.data.totalCount);
    setInvoice_numbers(response.data.data.invoice_numbers);
    setPo_numbers(response.data.data.po_numbers);
    setStore_codes(response.data.data.store_codes);
    setStore_descriptions(response.data.data.store_descriptions);
    setOrder_dates(response.data.data.order_dates);
    setDelivery_dates(response.data.data.delivery_dates);
    setCreated_dates(response.data.data.created_dates);
    setUser_names(response.data.data.user_names);
    setLoading(false);
  };

  useEffect(() => {
    fetchInvoices();
  }, [supplier, warehouse, retailer, filters]);

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage * limit < totalCount) {
      setPage(newPage);
      fetchInvoices();
    }
  };
  const handleFilterSubmit = (selectedFilters) => {
    setFilters(selectedFilters);
  };
  const click_download = async (invoice_number) => {
    setDownloadingInvoices((prev) => ({ ...prev, [invoice_number]: true }));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/invoices/download-invoice`,
        { retailer, organisation, invoice_number },
        {
          responseType: "blob",
        }
      );
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `order_${invoice_number}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    } finally {
      setDownloadingInvoices((prev) => ({ ...prev, [invoice_number]: false }));
    }
  };

  if (loading) return <Spinner />;

  return (
    <Flex flexDirection={"column"}>
      <FilteringCancelled onFilterSubmit={handleFilterSubmit} invoice_numbers={invoice_numbers} po_numbers={po_numbers} store_codes={store_codes} store_descriptions={store_descriptions} order_dates={order_dates} delivery_dates={delivery_dates} created_dates={created_dates} user_names={user_names} />
      <Box maxHeight="640px" overflowY={"scroll"}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead position="sticky" top="0" zIndex="1" backgroundColor="white">
            <Tr>
              <Th>View</Th>
              <Th>Print</Th>
              <Th>Supplier</Th>
              <Th>Invoice #</Th>
              <Th>Po #</Th>
              <Th>Store Code</Th>
              <Th>Store Name</Th>
              <Th>Region</Th>
              <Th>Distributor</Th>
              <Th># Cases</Th>
              <Th>Delivery Date</Th>
              <Th>Invoice Amount (incl)</Th>
              <Th>Comment</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoices.map((invoice, index) => (
              <Tr key={invoice.id}>
                <Td>
                  <IconButton variant="outline" colorScheme="customRed" icon={<FaEye />} size="sm" aria-label="View" onClick={() => handleViewInvoice(invoice)} />{" "}
                </Td>
                <Td>
  <IconButton
    variant="outline"
    colorScheme="gray"
    icon={downloadingInvoices[invoice.invoice_number] ? <Spinner size="sm" /> : <FaPrint />}
    size="sm"
    onClick={() => click_download(invoice.invoice_number)}
    isDisabled={downloadingInvoices[invoice.invoice_number]} // Optional: Disable button while downloading
  />
</Td>
                <Td>{invoice.supplier_name}</Td>
                <Td>{invoice.invoice_number}</Td>
                <Td>{invoice.po_number}</Td>
                <Td>{invoice.store_code}</Td>
                <Td>{invoice.store_description}</Td>
                <Td>{invoice.store_region}</Td>
                <Td>{invoice.distributor_name}</Td>
                <Td>{invoice.quantity}</Td>
                <Td>{invoice.delivery_date}</Td>
                <Td>{invoice.cost_incl}</Td>
                <Td>{invoice.comment}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {selectedInvoice && <ViewInvoice isOpen={isPopupOpen} onClose={handleCloseInvoice} invoice={selectedInvoice} />}
      </Box>
      <Flex justifyContent="space-between" mt={4}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          isDisabled={page === 0} // Disable Previous button if on the first page
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(page + 1)}
          isDisabled={(page + 1) * limit >= totalCount} // Disable Next button if there are no more invoices
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default InvoicedTable;
