import React, { useState, useEffect } from "react";
import { Box, Flex, Button, Table, Thead, Tbody, Tr, Th, Td, useColorModeValue, useTheme } from "@chakra-ui/react";
import axios from "axios";
import InvoiceStats from "./InvoiceStats";

const CaptureStep2 = ({ transaction_items, debriefData, onSubmit }) => {
  const theme = useTheme();
  const grayColor = useColorModeValue("#e6e6e6", "#dedede");
  const [loading, setLoading] = useState(false);
  const [debriefDetails, setDebriefData] = useState(null);
  const [totals, setTotals] = useState({});
  const [stats, setStats] = useState({});
  const [unbalancedRows, setUnbalancedRows] = useState([]);
  const [balance, setBalance] = useState(false);
  const [TOTALS, setTOTALS] = useState({});



  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/debriefs/upd-in-progress-debrief`, {
        cases_delivered: TOTALS.TOTAL_delivered,
        cases_returned:TOTALS.TOTAL_returned,
        invoice_number: debriefData.invoice_number,
      });


      onSubmit();

      setLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    calculateTotals();
  }, [transaction_items]);


  const calculateTotals = () => {
    let TOTAL_cases=0;
    let TOTAL_quantity=0;
    let TOTAL_delivered=0;
    let TOTAL_returned=0;

    let stats = {};
    let unbalanced_rows = [];
    for (let i = 0; i < transaction_items.length; i++) {
      let quantity = parseInt(transaction_items[i].quantity);

      let cases_delivered = parseInt(transaction_items[i].cases_delivered);
      let cases_returned = parseInt(transaction_items[i].cases_returned);
      let total_cases = cases_delivered+cases_returned;

      stats[transaction_items[i].articlecode] = { total_cases, quantity, index: i }; //must match

      TOTAL_quantity+=quantity;
      TOTAL_cases+=total_cases;
      TOTAL_delivered+=cases_delivered;
      TOTAL_returned+=cases_returned;

      if (total_cases !== quantity) {
        unbalanced_rows.push(transaction_items[i].articlecode);
      }


    }
    setUnbalancedRows(unbalanced_rows);
    setStats(stats);
    setTOTALS({TOTAL_cases,TOTAL_quantity,TOTAL_delivered,TOTAL_returned});

    if (TOTAL_cases==TOTAL_quantity) {
      setBalance(true);
    } else {
      setBalance(false);
    }
  };


  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <Table variant="striped" size="sm" maxWidth="300px" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
            <Thead>
              <Tr>
                <Th>Invoice Number</Th>
                <Th>PO Number</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr key={1}>
                <Td>{debriefData.invoice_number}</Td>
                <Td>{debriefData.po_number}</Td>
              </Tr>
            </Tbody>
          </Table>
          <InvoiceStats TOTALS={TOTALS} stats={stats} />
        </Flex>
      </Box>

      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th bg={grayColor}></Th>
              <Th bg={grayColor}></Th>
              <Th bg={grayColor}></Th>
              {debriefData.partial_delivery && <Th>Delivered</Th>}
              <Th colSpan={3} bg={grayColor} style={{ textAlign: "center" }}>
                Not Delivered
              </Th>
            </Tr>
            <Tr>
              <Th>Article Number</Th>
              <Th>Article Name</Th>
              <Th>Invoiced cases</Th>
              {debriefData.partial_delivery && <Th></Th>}
              <Th>Good Stock</Th>
              <Th>Bad Stock</Th>
              <Th>Missing</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transaction_items.map((article, index) => (
              <Tr key={index} sx={{ td: { backgroundColor: unbalancedRows.includes(article.articlecode.toString().trim()) ? "rgba(204, 0, 0, 0.6) !important" : "inherit", }, }} >
                <Td> {article.articlecode} </Td>
                <Td> {article.article_name} </Td>
                <Td> {article.quantity} </Td>
                {debriefData.partial_delivery && <Td> {article.cases_delivered} </Td>}
                <Td>{article.good_stock}</Td>
                <Td>{article.bad_stock}</Td>
                <Td>{article.missing_stock}</Td>
              </Tr>
            ))}
            <Tr>
              <Td></Td>
              <Td></Td>
              <Td style={{ fontWeight: "bold" }}>{debriefData.cases_invoiced}</Td>
              {debriefData.partial_delivery && <Td style={{ fontWeight: "bold" }}> {TOTALS.TOTAL_delivered} </Td>}
              <Td style={{ fontWeight: "bold" }}>{transaction_items.reduce((sum, item) => sum + parseInt(item.good_stock), 0)}</Td>
              <Td style={{ fontWeight: "bold" }}>{transaction_items.reduce((sum, item) => sum + parseInt(item.bad_stock), 0)}</Td>
              <Td style={{ fontWeight: "bold" }}>{transaction_items.reduce((sum, item) => sum + parseInt(item.missing_stock), 0)}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box mb={4}>
        {!debriefDetails && (
          <Button onClick={handleSubmit} isDisabled={!balance} isLoading={loading} loadingText="Submitting..." colorScheme="customRed" variant="outline">
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CaptureStep2;
