// CommentPopup.js
import React, { useState, useEffect } from "react";
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverFooter, PopoverArrow, PopoverCloseButton, Button, Textarea, Input, useToast } from "@chakra-ui/react";
import axios from "axios";

const CommentPopup = ({ invoice_number, initialComment, fetchInvoices, isOpen, onOpen, onClose }) => {
  const toast = useToast();
  const [comment, setComment] = useState(initialComment);

  // Update the local comment whenever the initial comment changes
  useEffect(() => {
    setComment(initialComment);
  }, [initialComment]);

  const handleCommentChange = (e) => {
    setComment(e.target.value); // Update the local state
  };

  const handleSubmitComment = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/upd-in-progress-comment`, {
        userid: localStorage.getItem("userid"),
        comment: comment,
        invoice_number: invoice_number,
      });

      if (response.data.status !== "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      } else {
        toast({
          title: "Success",
          description: "Comment updated successfully.",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
        fetchInvoices(); // Refresh invoices after successful submission
        onClose(); // Close the popup
      }
    } catch (error) {
      toast({
        title: "Technical Error",
        description: "An error occurred while updating the comment.",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Input value={comment} onClick={onOpen} readOnly borderRadius={"md"} size="sm" p={1} bg="white" />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Textarea value={comment} onChange={handleCommentChange} size="sm" placeholder="Enter comment" />
        </PopoverBody>
        <PopoverFooter>
          <Button size="sm" colorScheme="blue" onClick={handleSubmitComment}>
            Submit
          </Button>
          <Button size="sm" ml={2} onClick={onClose}>
            Cancel
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default CommentPopup;
