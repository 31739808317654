import React, { useState, useEffect } from "react";
import { Box, Button, Flex, Heading, Spinner, Table, Thead, Tbody, Tr, Td, Th, IconButton, Input } from "@chakra-ui/react";
import { FaEye, FaEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Capture from "./Capture";
import ViewItem from "../../ViewItem";

function Returns({ warehouse, supplier, distributor }) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setLoading(true);
    const hardcodedData = [
      {
        id: "ABC100",
        supplierRef: "",
        date: "2024-07-18",
        supplier: "Alettes",
        comment: "",
        totalCases: 10,
        articles: [],
        completed: false,
      },
      {
        id: "ABC102",
        supplierRef: "",
        date: "2024-07-18",
        supplier: "Alettes",
        comment: "",
        totalCases: 4,
        articles: [],
        completed: false,
      },
      {
        id: "ABC103",
        supplierRef: "",
        date: "2024-07-18",
        supplier: "Alettes",
        comment: "",
        totalCases: 10,
        articles: [],
        completed: false,
      },
    ];

    setTimeout(() => {
      setTableData(hardcodedData);
      setLoading(false);
    }, 1000);
  }, [supplier, warehouse, distributor]);

  const handleOpenView = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setIsPopupOpen(true);
  };

  const handleCloseView = () => {
    setIsPopupOpen(false);
    setSelectedItem(null);
    setSelectedIndex(null);
  };

  const handleOpenEdit = (item, index) => {
    setSelectedItem(item);
    setSelectedIndex(index);
    setEditMode(true);
  };

  const handleTdChange = (key, value, index) => {
    const newData = [...tableData];
    newData[index][key] = value;
    setTableData(newData);
  };
  if (loading) return <Spinner />;

  const handleEditComplete = (newArticles, newTotals, index) => {
    const updatedItem = {
      ...tableData[index],
      articles: newArticles,
      costExcl: newTotals.costExcl,
      costIncl: newTotals.costIncl,
      vat: newTotals.vat,
      completed: true
    };
    const filteredData = tableData.filter((item, i) => i !== index);
    const newTableData = [...filteredData, updatedItem];

    setTableData(newTableData);
    setEditMode(false);
    setSelectedItem(null);
    setSelectedIndex(null);
  };
  if (editMode) {
    return <Capture item={selectedItem} index={selectedIndex} onSubmit={handleEditComplete} onCancel={() => setEditMode(false)} />;
  }
  return (
    <Box>
      <Box display="flex" justifyContent="center" flexDirection={"column"} gap={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid" width="auto">
          <Thead>
            <Tr>
              <Th>View</Th>
              <Th>Edit</Th>
              <Th>ID</Th>
              <Th>Supplier Ref #</Th>
              <Th>Date</Th>
              <Th>Supplier</Th>
              <Th>Total Cases</Th>
              <Th>Comment</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData
              .filter((item) => !item.completed)
              .map((item, index) => (
                <Tr key={item.id}>
                  <Td>
                    <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="View" onClick={() => handleOpenView(item, index)} />
                  </Td>
                  <Td>
                    <IconButton variant="outline" colorScheme="green" icon={<FaEdit />} size="sm" aria-label="Edit" onClick={() => handleOpenEdit(item, index)} isDisabled={!item.supplierRef || !item.totalCases || isNaN(item.totalCases) || item.totalCases <= 0 || item.comment == ""} />
                  </Td>
                  <Td>{item.id}</Td>
                  <Td>
                    <Input borderRadius={"md"} size="sm" p={1} bg="white" value={item.supplierRef} onChange={(e) => handleTdChange("supplierRef", e.target.value, index)} />
                  </Td>
                  <Td>
                    <DatePicker placeholderText="Select Order Date" selected={item.date} onChange={(e) => handleTdChange("date", e.toLocaleDateString("en-CA", { year: "numeric", month: "2-digit", day: "2-digit" }), index)} dateFormat="yyyy/MM/dd" customInput={<Input width="200px" />} />
                  </Td>
                  <Td>{item.supplier}</Td>
                  <Td>
                    <Input borderRadius={"md"} size="sm" p={1} bg="white" value={item.totalCases} onChange={(e) => handleTdChange("totalCases", e.target.value, index)} />
                  </Td>
                  <Td>
                    <Input borderRadius={"md"} size="sm" p={1} bg="white" value={item.comment} onChange={(e) => handleTdChange("comment", e.target.value, index)} />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid" width="auto">
          <Thead>
            <Tr>
              <Th>View</Th>
              <Th>ID</Th>
              <Th>Supplier Ref #</Th>
              <Th>Date</Th>
              <Th>Supplier</Th>
              <Th>Total Cases</Th>
              <Th>Comment</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData
              .filter((item) => item.completed)
              .map((item, index) => (
                <Tr key={item.id}>
                  <Td>
                    <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="View" onClick={() => handleOpenView(item, index)} />
                  </Td>
                  <Td>{item.id}</Td>
                  <Td>{item.supplierRef} </Td>
                  <Td>{item.date} </Td>
                  <Td>{item.supplier}</Td>
                  <Td>{item.totalCases} </Td>
                  <Td>{item.comment}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      {selectedItem && <ViewItem isOpen={isPopupOpen} onClose={handleCloseView} item={selectedItem} />}
      {/* <ViewInvoice isOpen={isPopupOpen} onClose={handleCloseView} item={selectedItem} />} */}
    </Box>
  );
}

export default Returns;
