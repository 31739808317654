import React, { useState } from "react";
import { Flex, Input, Button, IconButton, Box, Select, CloseButton, Tag, TagLabel, TagCloseButton, Tooltip } from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa";

const FilteringOrders = ({ onFilterSubmit, order_codes, po_numbers, store_codes, store_descriptions, order_dates, delivery_dates, created_dates, user_names }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);


  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter" && selectedDropdownValue !== "-") {
      handleAddFilter(selectedFilter, selectedDropdownValue);
      setFilterOptions([]);
      setSelectedDropdownValue("");
      setSearchTerm("");
      setFilterOptions(internal_updateSelect());
      setShowTooltip(false);
    }
  };

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const handleAddFilter = (filterType, filterValue) => {
    const existingFilter = selectedFilters.find((filter) => filter.type === filterType && filter.value === filterValue);
    if (!existingFilter) {
      setSelectedFilters((prevFilters) => [...prevFilters, { type: filterType, value: filterValue }]);
    }
  };

  const handleRemoveFilter = (filterToRemove) => {
    setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter !== filterToRemove));
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    if (searchValue.length > 0) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false); // Hide tooltip if input is cleared
    }
    let dataToFilter = internal_updateSelect();
    if (searchValue === "") {
      //setFilterOptions([]);
      setSelectedDropdownValue("");
      setFilterOptions(internal_updateSelect());
    } else {
      const filteredOptions = dataToFilter.filter((item) => item && item.toLowerCase().includes(searchValue.toLowerCase()));
      if (filteredOptions.length > 0) {
        setFilterOptions(filteredOptions);
        setSelectedDropdownValue(filteredOptions[0]);
      } else {
        setFilterOptions(["-"]);
        setSelectedDropdownValue("-");
      }
    }
  };
  const handleSubmit = () => {
    setShowFilters(false);
    onFilterSubmit(selectedFilters);
  };
  //hardcoded values:
  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
    if (e.target.value === "order_code") {
      setFilterOptions(order_codes);
    } else if (e.target.value === "po_number") {
      setFilterOptions(po_numbers);
    } else if (e.target.value === "store_code") {
      setFilterOptions(store_codes);
    } else if (e.target.value === "store_description") {
      setFilterOptions(store_descriptions);
    } else if (e.target.value === "order_date") {
      setFilterOptions(order_dates);
    } else if (e.target.value === "delivery_date") {
      setFilterOptions(delivery_dates);
    } else if (e.target.value === "created_date") {
      setFilterOptions(created_dates);
    } else if (e.target.value === "user_name") {
      setFilterOptions(user_names);
    }
  };
  const internal_updateSelect = () => {
    let dataToFilter = [];
    switch (selectedFilter) {
      case "order_code":
        dataToFilter = order_codes;
        break;
      case "po_number":
        dataToFilter = po_numbers;
        break;
      case "store_code":
        dataToFilter = store_codes;
        break;
      case "store_description":
        dataToFilter = store_descriptions;
        break;
      case "order_date":
        dataToFilter = order_dates;
        break;
      case "delivery_date":
        dataToFilter = delivery_dates;
        break;
      case "created_date":
        dataToFilter = created_dates;
        break;
      case "user_name":
        dataToFilter = user_names;
        break;
      default:
        dataToFilter = [];
        break;
    }
    return dataToFilter;
  };

  return (
    <Box position="relative">
      <IconButton icon={<FaFilter />} aria-label="Toggle Filters" onClick={toggleFilters} mb={1} variant="outline" colorScheme="customRed" />

      {showFilters && (
        <Box position="absolute" top="-20%" left="30%" width="600px" height="600px" backgroundColor="rgba(255, 255, 255, 0.85)" display="flex" justifyContent="center" alignItems="center" zIndex="1000" boxShadow="md">
          <Box backgroundColor="white" p={6} borderRadius="md" boxShadow="lg" width="300px" height="450px" position="relative">
            <CloseButton position="absolute" right="10px" top="10px" onClick={toggleFilters} />

            <Flex mb={4} wrap="wrap" gap="8px">
              {selectedFilters.map((filter, index) => (
                <Tag key={index} size="md" colorScheme="blue" borderRadius="full">
                  <TagLabel>{`${filter.type}: ${filter.value}`}</TagLabel>
                  <TagCloseButton onClick={() => handleRemoveFilter(filter)} />
                </Tag>
              ))}
            </Flex>

            <Select placeholder="Select Filter" value={selectedFilter} onChange={handleFilterChange} mb={4}>
              <option value="order_code">Order Code</option>
              <option value="po_number">PO Number</option>
              <option value="store_code">Store Code</option>
              <option value="store_description">Store Name</option>

              <option value="order_date">Order Date</option>
              <option value="delivery_date">Delivery Date</option>
              <option value="created_date">Created Date </option>
              <option value="user_name">Created By</option>
            </Select>

            {selectedFilter && (
              <Box mb={4}>
                <Tooltip label="Press Enter to add filter" isOpen={showTooltip} placement="top" hasArrow>
                  <Input placeholder={`Search by ${selectedFilter}`} value={searchTerm} onChange={handleSearchChange} onKeyDown={handleSearchKeyDown} mb={2} />
                </Tooltip>
                <Select
                  placeholder={`Select ${selectedFilter}`}
                  value={selectedDropdownValue}
                  onChange={(e) => {
                    handleAddFilter(selectedFilter, e.target.value);
                    setSelectedDropdownValue(e.target.value);
                  }}
                >
                  {(filterOptions || [])
                    .filter((option) => option !== null && option !== undefined) // Filter out null/undefined values
                    .map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                </Select>
              </Box>
            )}

            <Button variant="solid" colorScheme="customRed" onClick={handleSubmit} width="100%">
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FilteringOrders;
