import React, { useState, useEffect } from "react";
import { Box, Button, Input, Select, Table, Thead, Tbody, Tr, Th, Td, Divider, useTheme, useToast } from "@chakra-ui/react";
import { FaPlus,FaMinus } from "react-icons/fa";

import axios from "axios";

const CaptureStep2 = ({ initialData, step1Data, onSubmit, supplier, retailer, organisation }) => {
  const theme = useTheme();
  const toast = useToast();
  const [articles, setArticles] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState(initialData || []);
  const [newArticle, setNewArticle] = useState({
    supplier_articlecode: "",
    article_desc: "",
    article_packsize: 12,
    article_quantity: 0,
    article_cost: 0,
    article_vat: 0,
    article_cost_incl: 0,
    article_soh: 0,
  });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchStores = async () => {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-dd-articles`, { supplier, retailer, organisation });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
        return;
      }
      setArticles(response.data.data.articles);
    };

    fetchStores();
  }, [supplier]);

  const addArticle = () => {
    setSelectedArticles([...selectedArticles, { ...newArticle, isSaved: false }]);
    setNewArticle({
      supplier_articlecode: "",
      article_desc: "",
      article_packsize: 0,
      article_quantity: "",
      article_cost: 0,
      article_vat: 0,
      article_cost_excl: 0,
      article_cost_incl: 0,
      article_soh: 0,
    });
  };

  const delArticle=(index)=>{
    setSelectedArticles(prevArticles => 
      prevArticles.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    onSubmit(selectedArticles);
  }, [selectedArticles, onSubmit]);

  const handleArticleCode = (index, value) => {
    const selectedArticle = articles.find((article) => article.supplier_articlecode === value);
    if (selectedArticle) {
      const article_vat = parseFloat(selectedArticle.article_cost * 0.15).toFixed(2);
      const article_cost_incl = parseFloat(selectedArticle.article_cost * 1.15).toFixed(2);
      const article_cost_excl = parseFloat(selectedArticle.article_cost).toFixed(2);

      const updatedArticles = selectedArticles.map((article, i) => {
        if (i === index) {
          return {
            ...article,
            supplier_articlecode: selectedArticle.supplier_articlecode,
            article_desc: selectedArticle.article_desc,
            article_packsize: selectedArticle.article_packsize,
            article_cost: selectedArticle.article_cost,
            article_cost_excl: article_cost_excl,
            article_vat: article_vat,
            article_cost_incl: article_cost_incl,
          };
        }
        return article;
      });

      setSelectedArticles(updatedArticles);
    }
  };

  const handleArticleDesc = (index, value) => {
    const selectedArticle = articles.find((article) => article.article_desc === value);

    if (selectedArticle) {
      const article_vat = parseFloat(selectedArticle.article_cost * 0.15).toFixed(2);
      const article_cost_incl = parseFloat(selectedArticle.article_cost * 1.15).toFixed(2);
      const article_cost_excl = parseFloat(selectedArticle.article_cost).toFixed(2);

      const updatedArticles = selectedArticles.map((article, i) => {
        if (i === index) {
          return {
            ...article,
            supplier_articlecode: selectedArticle.supplier_articlecode,
            article_desc: selectedArticle.article_desc,
            article_packsize: selectedArticle.article_packsize,
            article_cost: selectedArticle.article_cost,
            article_cost_excl: article_cost_excl,
            article_vat: article_vat,
            article_cost_incl: article_cost_incl,
          };
        }

        return article;
      });
      // console.log(updatedArticles);
      setSelectedArticles(updatedArticles);
    }
  };

  const handleQuantity = (index, value) => {
    let updatedArticles = selectedArticles.map((article, i) => {
      if (i === index) {
        const article_quantity = value === "" ? "" : parseInt(value, 10);
        const article_vat = article_quantity === "" ? 0 : parseFloat(article.article_cost * article_quantity * 0.15).toFixed(2);
        const article_cost_incl = article_quantity === "" ? 0 : parseFloat(article.article_cost * article_quantity * 1.15).toFixed(2);
        const article_cost_excl = article_quantity === "" ? 0 : parseFloat(article.article_cost * article_quantity).toFixed(2);
        return {
          ...article,
          article_quantity: article_quantity,
          article_vat: article_vat,
          article_cost_incl: article_cost_incl,
          article_cost_excl: article_cost_excl,
        };
      }
      return article;
    });
    setSelectedArticles(updatedArticles);
  };

  const handleArticleCost = (index, value) => {
    let updatedArticles = selectedArticles.map((article, i) => {
      if (i === index) {
        const article_cost = value === "" ? "" : parseFloat(value);
        const article_quantity = article.article_quantity;
        const article_vat = article_quantity === "" ? 0 : parseFloat(article_cost * article_quantity * 0.15).toFixed(2);
        const article_cost_incl = article_quantity === "" ? 0 : parseFloat(article_cost * article_quantity * 1.15).toFixed(2);
        const article_cost_excl = article_quantity === "" ? 0 : parseFloat(article_cost * article_quantity).toFixed(2);
        return {
          ...article,
          article_cost: article_cost,
          article_vat: article_vat,
          article_cost_incl: article_cost_incl,
          article_cost_excl: article_cost_excl,
        };
      }
      return article;
    });
    setSelectedArticles(updatedArticles);
  };

  const handleSoh = (index, value) => {
    let updatedArticles = selectedArticles.map((article, i) => (i === index ? { ...article, article_soh: value } : article));
    setSelectedArticles(updatedArticles);
  };

  const verify = async () => {
    const newErrors = selectedArticles.map((article) => ({
      supplier_articlecode: !article.supplier_articlecode,
      article_quantity: !article.article_quantity,
    }));
    setErrors(newErrors);
    // console.log({newErrors})

    if (newErrors.some((error) => Object.values(error).some((e) => e))) {
      alert("Please fill in all fields for each article.");
      return false;
    }
    return true;
  };

  CaptureStep2.verify = verify;

  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>PO Number</Th>
              <Th>Order Date</Th>
              <Th>Delivery Date</Th>
              <Th>Store Code</Th>
              <Th>Store Name</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{step1Data.po_number}</Td>
              <Td>{step1Data.order_date}</Td>
              <Td>{step1Data.delivery_date}</Td>
              <Td>{step1Data.store_code}</Td>
              <Td>{step1Data.store_name}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Divider />
      <Box mb={4}>
        <Table variant="simple" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>Article Number</Th>
              <Th>Article Name</Th>
              <Th>Pack Size</Th>
              <Th>Quantity</Th>
              <Th>Article Cost Price</Th>
              <Th>Total Cost (excl)</Th>
              <Th>VAT</Th>
              <Th>Total Cost (incl)</Th>
              <Th>SOH</Th>
              <Th>
                <Button rightIcon={<FaPlus />} onClick={addArticle} ml={2} size="xs" colorScheme="customRed" variant="outline">
                  Add
                </Button>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {selectedArticles.map((article, index) => (
              <Tr key={index}>
                <Td style={{ minWidth: "150px" }}>
                  <Select value={article.supplier_articlecode} onChange={(e) => handleArticleCode(index, e.target.value)} placeholder="Select Article" borderColor={errors[index]?.supplier_articlecode ? "crimson" : theme.colors.primary2} errorBorderColor="crimson">
                    {articles.map((article) => (
                      <option key={article.id} value={article.supplier_articlecode}>
                        {`${article.supplier_articlecode}`}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td style={{ minWidth: "350px" }}>
                  <Select value={article.article_desc} onChange={(e) => handleArticleDesc(index, e.target.value)} placeholder="Select Article Description" borderColor={errors[index]?.article_desc ? "crimson" : theme.colors.primary2} errorBorderColor="crimson">
                    {articles.map((article) => (
                      <option key={article.id} value={article.article_desc}>
                        {article.article_desc}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td>
                  <Input type="number" disabled value={article.article_packsize} borderColor={errors[index]?.article_packsize ? "crimson" : theme.colors.primary2} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.article_quantity} onChange={(e) => handleQuantity(index, e.target.value)} borderColor={errors[index]?.article_quantity ? "crimson" : theme.colors.primary2} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.article_cost} onChange={(e) => handleArticleCost(index, e.target.value)} borderColor={errors[index]?.article_cost ? "crimson" : theme.colors.primary2} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" disabled value={article.article_cost_excl} borderColor={errors[index]?.article_cost_excl ? "crimson" : theme.colors.primary2} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" disabled value={article.article_vat} borderColor={errors[index]?.article_vat ? "crimson" : theme.colors.primary2} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" disabled value={article.article_cost_incl} borderColor={errors[index]?.article_cost_incl ? "crimson" : theme.colors.primary2} errorBorderColor="crimson" />
                </Td>
                <Td>
                  <Input type="number" value={article.article_soh} onChange={(e) => handleSoh(index, e.target.value)} borderColor={errors[index]?.article_soh ? "crimson" : theme.colors.primary2} errorBorderColor="crimson" />
                </Td>
                <Td>
                <Button rightIcon={<FaMinus />} onClick={()=>delArticle(index)} ml={2} size="xs" colorScheme="customRed" variant="outline">
                 Del
                </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default CaptureStep2;
