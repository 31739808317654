import React, { useState, useEffect } from "react";
import { Box, Spinner, Input, Flex, Button, useTheme,useToast } from "@chakra-ui/react";
import { FaPrint } from "react-icons/fa";
import axios from "axios";
import SubNav from "../../components/SubNav";

//import Capture from "./components/editDetails/Capture";
import InProgress from "./components/InProgress";
import Debriefed from "./components/Debriefed";
//import Pop from "./components/Pop";

import { useSelection } from "../../wrappers/SelectionContext";

const Debriefs = () => {
  const { selectedOrganisation, selectedWarehouse, selectedSupplier, selectedRetailer } = useSelection();
  const theme = useTheme();
  const toast=useToast();
  const [isUploading1, setIsUploading1] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [isUploading3, setIsUploading3] = useState(false);


  const [distributors, setDistributors] = useState([]);
  const [loadingState, setLoadingState] = useState({});



  const tabData = [
    {
      label: "In-Progress",
      component: <InProgress organisation={selectedOrganisation} warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} />,
    },
    {
      label: "Debriefed",
      component: <Debriefed organisation={selectedOrganisation} warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} />,
    },
  ];

  const downloadDistributors = async (distributorcode) => {
    setLoadingState((prevState) => ({
      ...prevState,
      [distributorcode]: true,
    }));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/debriefs/download-debriefs-by-distributor`,
        { organisation:selectedOrganisation, distributor:distributorcode,retailer:selectedRetailer },
        {
          responseType: "blob", // important to get the response as a blob
        }
      );

      // Create a URL for the blob and download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `debriefs_${distributorcode}.pdf`); // set the file name
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error uploading:", error);
    } finally {
      // Reset loading state after the operation is complete
      setLoadingState((prevState) => ({
        ...prevState,
        [distributorcode]: false,
      }));
    }
  };

  const fetchDistributors = async () => {
    //setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-dd-distributors`, { organisation:selectedOrganisation, retailer:selectedRetailer, supplier:selectedSupplier,warehouse:selectedWarehouse});
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      console.log(response.data.data.distributors)
      setDistributors(response.data.data.distributors);
    } catch (error) {
      toast({
        title: "Technical Error",
        description: "Unexpcted error occured",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    } finally {
     // setLoading(false);
    }
  };

  useEffect(() => {
   // fetchDistributors();

  }, [selectedOrganisation,selectedWarehouse,selectedSupplier,selectedRetailer]);

  return (
    <Box p={4} display="flex" flexDirection="column" height="99vh">
      <SubNav mt={12} data={tabData} />

      <Box flexGrow={1} />


      {/* <Flex gap="20px">
        {distributors.map((distributor) => (
          <Button key={distributor.value} leftIcon={loadingState[distributor.value] ? <Spinner /> : <FaPrint />} 
          onClick={() => downloadDistributors(distributor.value)} isLoading={loadingState[distributor.value]} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
            {distributor.text}
          </Button>
        ))}
      </Flex> */}


    </Box>
  );
};
export default Debriefs;
