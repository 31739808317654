import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Button, Input, Box, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useTheme } from "@chakra-ui/react";
import axios from "axios";
import { formatDecimals } from "../../../../components/GenericFunctions";

const EditOrder = ({ isOpen, onClose, invoice_number, onSave, supplier, retailer, organisation }) => {
  const [orderItems, setOrderItems] = useState([]);
  const [editedItems, setEditedItems] = useState([]);

  const toast = useToast();
  const theme = useTheme();

  useEffect(() => {
    if (isOpen && invoice_number) {
      const fetchOrderItems = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transaction-items`, {
            invoice_number: invoice_number,
            supplier,
            retailer,
            organisation,
          });
          setOrderItems(response.data.data.transaction_items);
          console.log(response.data.data.transaction_items);
        } catch (error) {
          toast({
            title: "Failed to load items",
            description: "Technical error occurred",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      };
      fetchOrderItems();
    }
  }, [isOpen, invoice_number, toast]);

  const handleInputChange = (index, field, value) => {
    setOrderItems((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, [field]: value } : item)));
  };

  const quantity_change = (index, value) => {
    let order_item = orderItems[index];
    let article_cost = parseFloat(order_item.article_cost);
    let quantity = parseInt(value);
    if (isNaN(quantity)) {
      quantity = 0;
    }

    let cost_excl = quantity * article_cost;
    let cost_incl = cost_excl * 1.15;
    let cost_vat = cost_incl - cost_excl;

    order_item.quantity = value;
    order_item.cost_excl = formatDecimals(cost_excl);
    order_item.cost_incl = formatDecimals(cost_incl);
    order_item.cost_vat = formatDecimals(cost_vat);

    //update orderItems
    const updatedOrderItems = [...orderItems];
    updatedOrderItems[index] = order_item;
    setOrderItems(updatedOrderItems);
  };

  const handleSave = async () => {
    try {
      console.log(orderItems);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/upd-transaction-items`, {
        invoice_number: invoice_number,
        items: orderItems,
      });
      toast({
        title: response.data.title,
        description: response.data.message,
        status: response.data.status,
        duration: 3000,
        isClosable: true,
      });
      const orderDetails = response.data.data;
      onSave(orderDetails);
      onClose();
    } catch (error) {
      toast({
        title: "Failed to update order",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="1300px" maxWidth="100vw">
        <ModalHeader>Edit Order Items</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box maxHeight="400px" overflowY="scroll">
            <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
              <Thead>
                <Tr>
                  <Th>Supplier Article Code</Th>
                  <Th>Article Description</Th>
                  <Th>Article Pack Size</Th>
                  <Th>Article Cost</Th>
                  <Th>Quantity</Th>
                  <Th>Cost (excl)</Th>
                  <Th>VAT</Th>
                  <Th>Cost (incl)</Th>
                  <Th>SOH</Th>
                </Tr>
              </Thead>
              <Tbody>
                {orderItems.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.articlecode}</Td>
                    <Td>{item.article_name}</Td>
                    <Td>{item.article_packsize}</Td>
                    <Td>
                      <Input borderColor={theme.colors.secondary1} value={item.article_cost} onChange={(e) => handleInputChange(index, "article_cost", e.target.value)} size="sm" />
                    </Td>
                    <Td>
                      <Input borderColor={theme.colors.secondary1} value={item.quantity} onChange={(e) => quantity_change(index, e.target.value)} size="sm" />
                    </Td>
                    <Td>
                      <Input borderColor={theme.colors.secondary1} value={formatDecimals(item.cost_excl)} onChange={(e) => handleInputChange(index, "cost_excl", e.target.value)} size="sm" />
                    </Td>
                    <Td>
                      <Input borderColor={theme.colors.secondary1} value={formatDecimals(item.cost_vat)} onChange={(e) => handleInputChange(index, "cost_vat", e.target.value)} size="sm" />
                    </Td>
                    <Td>
                      <Input borderColor={theme.colors.secondary1} value={formatDecimals(item.cost_incl)} onChange={(e) => handleInputChange(index, "cost_incl", e.target.value)} size="sm" />
                    </Td>
                    <Td>
                      <Input borderColor={theme.colors.secondary1} value={item.article_soh} onChange={(e) => handleInputChange(index, "article_soh", e.target.value)} size="sm" />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button colorScheme="customRed" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditOrder;
