import React, { useState } from "react";
import { Flex, Input, Button, IconButton, Box, Select, CloseButton, Tag, TagLabel, TagCloseButton, Tooltip } from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa";

const Filters = ({ onFilterSubmit, date_receiveds, supplier_reference_numbers, distributorcodes, suppliercodes }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter" && selectedDropdownValue !== "-") {
      handleAddFilter(selectedFilter, selectedDropdownValue);
      setFilterOptions([]);
      setSelectedDropdownValue("");
      setSearchTerm("");
      setFilterOptions(internal_updateSelect());
      setShowTooltip(false);
    }
  };

  const toggleFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const handleAddFilter = (filterType, filterValue) => {
    const existingFilter = selectedFilters.find((filter) => filter.type === filterType && filter.value === filterValue);
    if (!existingFilter) {
      setSelectedFilters((prevFilters) => [...prevFilters, { type: filterType, value: filterValue }]);
    }
  };

  const handleRemoveFilter = (filterToRemove) => {
    setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter !== filterToRemove));
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    if (searchValue.length > 0) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false); // Hide tooltip if input is cleared
    }
    let dataToFilter = internal_updateSelect();
    if (searchValue === "") {
      //setFilterOptions([]);
      setSelectedDropdownValue("");
      setFilterOptions(internal_updateSelect());
    } else {
      const filteredOptions = dataToFilter.filter((item) => item && item.toLowerCase().includes(searchValue.toLowerCase()));
      if (filteredOptions.length > 0) {
        setFilterOptions(filteredOptions);
        setSelectedDropdownValue(filteredOptions[0]);
      } else {
        setFilterOptions(["-"]);
        setSelectedDropdownValue("-");
      }
    }
  };
  const handleSubmit = () => {
    setShowFilters(false);
    onFilterSubmit(selectedFilters);
  };
  //hardcoded values:
  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
    if (e.target.value === "date_received") {
      setFilterOptions(date_receiveds);
    } else if (e.target.value === "supplier_reference_number") {
      setFilterOptions(supplier_reference_numbers);
    } else if (e.target.value === "distributorcode") {
      setFilterOptions(distributorcodes);
    } else if (e.target.value === "suppliercode") {
      setFilterOptions(suppliercodes);
    }
  };
  const internal_updateSelect = () => {
    let dataToFilter = [];
    switch (selectedFilter) {
      case "date_received":
        dataToFilter = date_receiveds;
        break;
      case "supplier_reference_number":
        dataToFilter = supplier_reference_numbers;
        break;
      case "distributorcode":
        dataToFilter = distributorcodes;
        break;
      case "suppliercode":
        dataToFilter = suppliercodes;
        break;
      default:
        dataToFilter = [];
        break;
    }
    return dataToFilter;
  };

  return (
    <Box position="relative">
      <IconButton icon={<FaFilter />} aria-label="Toggle Filters" onClick={toggleFilters} mb={1} variant="outline" colorScheme="customRed" />

      {showFilters && (
        <Box
          position="absolute"
          top="-20%"
          right="25vw"
          width="600px"
          height="600px"
          backgroundColor="rgba(255, 255, 255, 0.85)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="1000"
          border="2px solid rgba(0, 0, 0, 0.2)" // Adds a subtle border
          borderRadius="8px"
          boxShadow="0px 4px 15px rgba(0, 0, 0, 0.4)"
        >
          <Box backgroundColor="white" p={6} borderRadius="md" boxShadow="lg" width="300px" height="450px" position="relative">
            <CloseButton position="absolute" right="10px" top="10px" onClick={toggleFilters} />

            <Flex mb={4} wrap="wrap" gap="8px">
              {selectedFilters.map((filter, index) => (
                <Tag key={index} size="md" colorScheme="blue" borderRadius="full">
                  <TagLabel>{`${filter.type}: ${filter.value}`}</TagLabel>
                  <TagCloseButton onClick={() => handleRemoveFilter(filter)} />
                </Tag>
              ))}
            </Flex>

            <Select placeholder="Select Filter" value={selectedFilter} onChange={handleFilterChange} mb={4}>
              <option value="date_received">date Received</option>
              <option value="supplier_reference_number">Supplier Reference Number</option>
              <option value="distributorcode">Distributor</option>
              <option value="suppliercode">Suppliers</option>
            </Select>

            {selectedFilter && (
              <Box mb={4}>
                <Tooltip label="Press Enter to add filter" isOpen={showTooltip} placement="top" hasArrow>
                  <Input placeholder={`Search by ${selectedFilter}`} value={searchTerm} onChange={handleSearchChange} onKeyDown={handleSearchKeyDown} mb={2} />
                </Tooltip>
                <Select
                  placeholder={`Select ${selectedFilter}`}
                  value={selectedDropdownValue}
                  onChange={(e) => {
                    handleAddFilter(selectedFilter, e.target.value);
                    setSelectedDropdownValue(e.target.value);
                  }}
                >
                  {(filterOptions || [])
                    .filter((option) => option !== null && option !== undefined) // Filter out null/undefined values
                    .map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                </Select>
              </Box>
            )}

            <Button variant="solid" colorScheme="customRed" onClick={handleSubmit} width="100%">
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Filters;
