import React from "react";
import { Box,  Heading,  Tab, TabList, TabPanel, TabPanels, Tabs, useTheme } from "@chakra-ui/react";
import { useSelection } from "../../wrappers/SelectionContext";

import InProgressTable from "./components/inProgress/InProgressTable";
import ArchivedTable from "./components/cancelled/ArchivedTable";
import InvoicedTable from "./components/invoiced/InvoicedTable";
import Import from "./components/import/Import";

import SubNav from '../../components/SubNav';

const Invoices = () => {
  const theme = useTheme();
  const {selectedOrganisation, selectedWarehouse, selectedSupplier, selectedRetailer,selectedDistributor } = useSelection();
  const tabData = [
    {
      label: "In-Progress",
      component:<InProgressTable organisation={selectedOrganisation} warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} />
    },
    {
      label: "Invoiced",
      component:  <InvoicedTable organisation={selectedOrganisation} warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer}/>
    },
    {
      label: "Cancelled",
      component: <ArchivedTable organisation={selectedOrganisation} warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} />
    },
    {
      label: "Import",
      component: <Import organisation={selectedOrganisation} warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} distributor={selectedDistributor} />
    },
  ];

  
  return (
    <Box p={4} >
      <SubNav mt={12} data={tabData} />
    </Box>
  );
};

export default Invoices;
