import React, { useState } from "react";
//import axios from "axios";
import { Box, useTheme, Spinner, Button, Flex } from "@chakra-ui/react";

import { useSelection } from "../../wrappers/SelectionContext";

import SubNav from "../../components/SubNav";
import Capture from "./components/Capture";
import Summary from "./components/Summary";

import { FaPrint } from "react-icons/fa";

const Orders = () => {
  const theme = useTheme();
  const { selectedOrganisation,selectedWarehouse, selectedSupplier, selectedDistributor,selectedRetailer } = useSelection();
  const [isUploading1, setIsUploading1] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [isUploading3, setIsUploading3] = useState(false);
  const [isUploading4, setIsUploading4] = useState(false);
  const tabData = [
    {
      label: "Capture",
      component: <Capture organisation={selectedOrganisation} retailer={selectedRetailer} warehouse={selectedWarehouse} supplier={selectedSupplier} distributor={selectedDistributor} />,
    },
    {
      label: "Summary",
      component: <Summary organisation={selectedOrganisation} warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={""}  distributor={selectedDistributor}/>,
    },
  ];

  const handleFileUpload1 = () => {
    setIsUploading1(true);
    setTimeout(() => {
      setIsUploading1(false);
    }, 2000); // Simulate uploading process
  };

  const handleFileUpload2 = () => {
    setIsUploading2(true);
    setTimeout(() => {
      setIsUploading2(false);
    }, 2000); // Simulate uploading process
  };

  const handleFileUpload3 = () => {
    setIsUploading3(true);
    setTimeout(() => {
      setIsUploading3(false);
    }, 2000); // Simulate uploading process
  };

  const handleFileUpload4 = () => {
    setIsUploading4(true);
    setTimeout(() => {
      setIsUploading4(false);
    }, 2000); // Simulate uploading process
  };

  return (
    <Box p={4} display="flex" flexDirection="column" height="96vh" overflow={"hidden"} >
      <SubNav mt={12} data={tabData} />
      {/* button group at bottom of page */}
      <Box flexGrow={1} />
      <Flex gap="20px">
        <Button as="span" mt={4} leftIcon={isUploading1 ? <Spinner /> : <FaPrint />} onClick={handleFileUpload1} isLoading={isUploading1} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
          Item History
        </Button>
        <Button as="span" mt={4} leftIcon={isUploading2 ? <Spinner /> : <FaPrint />} onClick={handleFileUpload2} isLoading={isUploading2} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
          Stock Summary
        </Button>
        <Button as="span" mt={4} leftIcon={isUploading3 ? <Spinner /> : <FaPrint />} onClick={handleFileUpload3} isLoading={isUploading3} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
          Expired/Damaged Stock
        </Button>
        <Button as="span" mt={4} leftIcon={isUploading4 ? <Spinner /> : <FaPrint />} onClick={handleFileUpload4} isLoading={isUploading4} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
          Lost Sales
        </Button>
      </Flex>
    </Box>
  );
};

export default Orders;
