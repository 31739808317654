import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, useToast, Box, Flex, Input } from "@chakra-ui/react";
import { FaTrash, FaPrint, FaEye, FaEdit } from "react-icons/fa";
import FilteringOrders from "../filtering/FilteringOrders";
import axios from "axios";

import ViewOrder from "./ViewOrder";
import EditOrder from "./EditOrder";

const History = ({ supplier, retailer, organisation, distributor }) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrderItems, setSelectedOrderItems] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedOrderCode, setSelectedOrderCode] = useState(null);

  const [filters, setFilters] = useState([]);

  const [order_codes, setOrder_codes] = useState([]);
  const [po_numbers, setPo_numbers] = useState([]);
  const [store_codes, setStore_codes] = useState([]);
  const [store_descriptions, setStore_descriptions] = useState([]);

  const [order_dates, setOrder_dates] = useState([]);
  const [delivery_dates, setDelivery_dates] = useState([]);
  const [created_dates, setCreated_dates] = useState([]);
  const [user_names, setUser_names] = useState([]);

  const toast = useToast();
  const fetchOrders = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transactions`, 
        { organisation, retailer, supplier, filters , entry:'orders' });
      setOrders(response.data.data.transactions);
      setOrder_codes(response.data.data.order_codes);
      setPo_numbers(response.data.data.po_numbers);
      setStore_codes(response.data.data.store_codes);
      setStore_descriptions(response.data.data.store_descriptions);
      setOrder_dates(response.data.data.order_dates);
      setDelivery_dates(response.data.data.delivery_dates);
      setCreated_dates(response.data.data.created_dates);
      setUser_names(response.data.data.user_names);

      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Failed to load orders",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [supplier, retailer, organisation, filters]);

  const handleViewOrder = async (order_code) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transaction-items`, 
        { invoice_number:order_code, supplier, retailer, organisation }); 
      setSelectedOrderItems(response.data.data.transaction_items);
      setIsViewModalOpen(true);
    } catch (error) {
      toast({
        title: "Failed to load order items",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditOrder = async (order_code) => {
    setSelectedOrderCode(order_code);
    setIsEditModalOpen(true);
  };
  //{order_code,cost_incl,cost_excl,cost_vat,cases}
  const updateOrderValues = ({ invoice_number, cost_incl, cost_excl, cost_vat, quantity }) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.invoice_number === invoice_number
          ? {
              ...order,
              cost_incl,
              cost_excl,
              cost_vat,
              quantity,
            }
          : order
      )
    );
  };
  const handlePrintOrder = async (order_code) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/download-order`,
        { supplier, retailer, organisation, invoice_number:order_code },
        {
          responseType: "blob", // important to get the response as a blob
        }
      );

      // Create a URL for the blob and download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `order_${order_code}.pdf`); // set the file name
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };

  const handleDeleteOrder = async (order_code) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders/delete-order`, 
        { organisation, retailer, supplier, invoice_number:order_code });
      setOrders(orders.filter((order) => order.invoice_number !== order_code));
      toast({
        title: "Order deleted",
        description: "The order has been deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed to delete order",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleFilterSubmit = (selectedFilters) => {
    setFilters(selectedFilters);
  };

  return (
    <Box>
      {isLoading ? (
        <Box>Loading...</Box>
      ) : (
        <Box>
          <FilteringOrders onFilterSubmit={handleFilterSubmit} order_codes={order_codes} po_numbers={po_numbers} store_codes={store_codes} store_descriptions={store_descriptions} order_dates={order_dates} delivery_dates={delivery_dates} created_dates={created_dates} user_names={user_names}/>
          <Box maxHeight="650px" overflowY="scroll">
            <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
              <Thead position="sticky" top="0" zIndex="1" backgroundColor="white">
                <Tr>
                  <Th>PO Number</Th>
                  <Th>Order Date</Th>
                  <Th>Delivery Date</Th>
                  <Th>Store Code</Th>
                  <Th>Store Name</Th>
                  <Th>Total Incl</Th>
                  <Th>Total Excl</Th>
                  <Th>Total VAT</Th>
                  <Th>Total Quantity</Th>
                  <Th>Created Date</Th>
                  <Th>Created By</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {orders.map((order) => (
                  <Tr key={order.invoice_number}>
                    <Td>{order.po_number}</Td>
                    <Td>{order.order_date}</Td>
                    <Td>{order.delivery_date}</Td>
                    <Td>{order.store_code}</Td>
                    <Td>{order.store_description}</Td>
                    <Td>{order.cost_incl}</Td>
                    <Td>{order.cost_excl}</Td>
                    <Td>{order.cost_vat}</Td>
                    <Td>{order.quantity}</Td>
                    <Td>{order.created_date}</Td>
                    <Td>{order.user_name}</Td>
                    <Td>
                      <IconButton variant="outline" colorScheme="blue" size="sm" icon={<FaEye />} onClick={() => handleViewOrder(order.invoice_number)} aria-label="View Order" mr={1} />
                      <IconButton variant="outline" colorScheme="green" size="sm" icon={<FaPrint />} onClick={() => handlePrintOrder(order.invoice_number)} aria-label="Print Order" mr={1} />
                      <IconButton variant="outline" colorScheme="yellow" size="sm" icon={<FaEdit />} onClick={() => handleEditOrder(order.invoice_number)} aria-label="Edit Order" mr={1} />
                      <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaTrash />} onClick={() => handleDeleteOrder(order.invoice_number)} aria-label="Delete Order" mr={1} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      )}

      <ViewOrder isOpen={isViewModalOpen} onClose={() => setIsViewModalOpen(false)} orderItems={selectedOrderItems} />

      <EditOrder
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        invoice_number={selectedOrderCode}
        supplier={supplier}
        retailer={retailer}
        organisation={organisation}
        onSave={(updatedValues) => {
          updateOrderValues(updatedValues);
          setIsEditModalOpen(false);
        }}
      />
    </Box>
  );
};

export default History;
