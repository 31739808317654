import React, { useState, useEffect } from "react";
import {Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, Input, Button, Image, useTheme, Spinner, useToast } from "@chakra-ui/react";
import { FaPrint } from "react-icons/fa";
import { storage } from "../../../tools/firebase"; // Adjust the path as necessary
import { ref, getDownloadURL, uploadBytes,getBlob } from "firebase/storage";
import axios from "axios";

const Pop = ({ isOpen, onClose, row, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [debrief, setDebrief] = useState({});
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");
  const toast = useToast();

  const theme = useTheme();

  useEffect(() => {
    setDebrief(row);
  }, [row]);

  useEffect(() => {
    if (row.pop.isset) {
      const imageRef = ref(storage, `stock-spearbi/debriefs/pop/${row.pop.name}`);
      setIsLoading(true);
      getDownloadURL(imageRef)
        .then((url) => {
          setImage(url);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error loading image", error);
          setIsLoading(false);
          toast({
            title: "Failed to load image.",
            description: "Unable to fetch initial image.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  }, []);

  useEffect(() => {
    if (!file) {
      setPreview("");
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // Clean up
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (!file) {
      toast({
        title: "No file selected.",
        description: "Please choose a file to upload first.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    const customName = `uploaded_${Date.now()}.jpg`; // Custom name based on timestamp
    const fileRef = ref(storage, `stock-spearbi/debriefs/pop/${customName}`);

    uploadBytes(fileRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setImage(url);
          toast({
            title: "Upload successful.",
            description: "Your image has been uploaded and is now being displayed.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          axios.post(`${process.env.REACT_APP_API_URL}/debriefs/upd-in-progress-pop`, {
            po_number: debrief.po_number,
            invoice_number:debrief.invoice_number,
            pop: customName,
          });


          if (typeof onUpdate === "function") {
            onUpdate({
              ...debrief,
              pop: { ...debrief.pop, isset: true, name: customName }, // Ensure `isset` is updated
            });
          }
          onClose();
        });
      })
      .catch((error) => {
        console.error("Error uploading image", error);
        toast({
          title: "Upload failed.",
          description: "There was a problem uploading your image.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleDownload = () => {
    if (!image) {
      toast({
        title: "No image to download.",
        description: "Please upload an image first.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    setIsDownloading(true);
  
    fetch(image)
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `debrief_image_${Date.now()}.jpg`); // Set custom filename
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading image", error);
        toast({
          title: "Download failed.",
          description: "There was a problem downloading the image.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader colorScheme="customRed">POP Image</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl >{isLoading ? <Spinner /> : <Image  src={preview || image} alt="Debrief POP" boxSize="300px" objectFit="cover" />}</FormControl>
          <FormControl mt={4}>
            <Input type="file" onChange={handleFileChange} accept="image/*" />
          </FormControl>
          <Flex gap="20px" justifyContent={"space-between"}>
          <Button mt={4} colorScheme="customRed" isLoading={isLoading} onClick={handleUpload} variant="outline">
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
          <Button as="span" mt={4} leftIcon={isDownloading ? <Spinner /> : <FaPrint />} onClick={handleDownload} isLoading={isDownloading} loadingText="Downloading.." cursor="pointer" colorScheme="customRed" variant="outline">
          Download POD
        </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Pop;
